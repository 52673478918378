<template>
  <v-dialog v-model="showDialogTransfer" width="800" scrollable persistent>
    <v-card class="transfer-form">
      <v-card-title class="headline primary white--text" primary-title>Transfer location</v-card-title>
      <v-card-text>
        <v-form style="max-width: 450px; margin: 0 auto" ref="transferForm">
          <!-- select type -->
          <v-radio-group v-model="transferType" row>
            <v-radio label="Vial" value="vial"></v-radio>
            <v-radio label="Dosage" value="dosage"></v-radio>
          </v-radio-group>

          <!-- Amount of vials-->
          <div class="d-flex" v-if="transferType == 'vial'" :key="'vial'">
            <v-text-field
              label="Amount of vials"
              type="number"
              class="required"
              v-model="formData.amountOfVials"
              :rules="[(v) => !!v || 'This field is required']"
              :disabled="formData.fullBox"
            ></v-text-field>
            <v-checkbox v-model="formData.fullBox" class="ms-2">
              <template v-slot:label>
                <span class="caption">Full Box</span>
              </template>
            </v-checkbox>
          </div>
          <!-- dosage -->
          <div v-else-if="'dose'" :key="'dosage'">
            <v-text-field
              label="Amount of doses"
              type="number"
              class="required"
              v-model="formData.totalTransferDosage"
              :rules="[(v) => !!v || 'This field is required']"
              :disabled="formData.fullBox"
            ></v-text-field>
          </div>

          <!-- Location -->
          <v-select
            :items="locations"
            item-text="name"
            item-value="id"
            label="Location"
            v-model="formData.location"
            :rules="[(v) => !!v || 'This field is required']"
            class="required"
          ></v-select>

          <!-- note -->
          <v-textarea
            class="mt-4"
            outlined
            :counter="maxCommentChars"
            rows="3"
            label="Comment"
            :rules="rulesComment"
            v-model="formData.note"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()">Cancel</v-btn>
        <v-btn color="primary" :disabled="submittingForm" @click="submit()" class="ma-2">
          <v-icon left dark>mdi-swap-horizontal</v-icon>Transfer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TransferFormDataKeys } from '@/views/VaccineViews/VaccineInventoryPage/data/transfer-form-data.js';

export default {
  props: ['showDialogTransfer', 'locations', 'selectedItem', 'search'],
  data() {
    return {
      submittingForm: false,
      formDataPrestine: JSON.stringify(new TransferFormDataKeys()),
      formData: new TransferFormDataKeys(),
      maxCommentChars: 250,
      transferType: 'vial',
      rulesComment: [(v) => v == '' || v == null || (v && v.length <= this.maxCommentChars) || `Max ${this.maxCommentChars} characters`],
    };
  },
  watch: {
    showDialogTransfer: {
      immediate: true,
      handler(val) {
        if (val) {
          this.formData.amountOfVials = this.selectedItem.amountOfVials;
        }
      },
    },
    selectedItem(val) {
      if (val) {
        this.formData.amountOfVials = val.amountOfVials;
      }
    },
    fullBox(n) {
      if (n) this.formData.amountOfVials = this.selectedItem.amountOfVials;
      else this.formData.amountOfVials = null;
    },
  },
  computed: {
    fullBox() {
      return this.formData.fullBox;
    },
  },
  methods: {
    submit() {
      if (!this.$refs.transferForm.validate()) return;
      if (this.formData.fullBox) {
        this.transferFullBox();
      } else if (this.transferType == 'vial') {
        this.transferVials();
      } else if (this.transferType == 'dosage') {
        this.transferDosage();
      }
    },

    closeModal() {
      this.$parent.showDialogTransfer = false;
      this.resetForm();
    },
    resetForm() {
      this.formData = JSON.parse(this.formDataPrestine);
    },
    transferVials() {
      const payload = {
        location: this.formData.location,
        amountOfVials: parseInt(this.formData.amountOfVials),
        id: this.selectedItem.id,
        note: this.formData.note ? this.formData.note : 'None',
      };

      this.submittingForm = true;
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment...' });
      this.$store
        .dispatch('transferVaccineVials', payload)
        .then((data) => {
          if (data) {
            this.$parent.getVaccineEntriesFiltered();
            this.$parent.showDialogTransfer = false;
            this.resetForm();
            this.$store.commit('SET_SNACKBAR', { open: true, text: 'Successfully transfered vials.', color: 'success' });
          }
        })
        .finally(() => {
          this.submittingForm = false;
          this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        });
    },
    transferFullBox() {
      const payload = {
        location: this.formData.location,
        allOfBox: true,
        id: this.selectedItem.id,
        note: this.formData.note ? this.formData.note : 'None',
      };

      this.submittingForm = true;
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment...' });
      this.$store.dispatch('transferVaccineBox', payload).then((data) => {
        this.submittingForm = false;
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        this.resetForm();
        this.$parent.getVaccineEntriesFiltered();
        this.$parent.showDialogTransfer = false;

        if (data) {
          this.resetForm();
          let payload = {
            open: true,
            text: 'Entry successfully created.',
            color: 'success',
          };
          this.$store.commit('SET_SNACKBAR', payload);
        }
      });
    },
    transferDosage() {
      const payload = {
        location: this.formData.location,
        totalTransferDosage: parseInt(this.formData.totalTransferDosage),
        id: this.selectedItem.id,
        note: this.formData.note ? this.formData.note : 'None',
      };

      this.submittingForm = true;
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment...' });
      this.$store
        .dispatch('transferVaccineDosage', payload)
        .then((data) => {
          if (data) {
            this.$parent.getVaccineEntriesFiltered();
            this.$parent.showDialogTransfer = false;
            this.resetForm();
            this.$store.commit('SET_SNACKBAR', { open: true, text: 'Successfully transfered dosage.', color: 'success' });
          }
        })
        .finally(() => {
          this.submittingForm = false;
          this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        });
    },
  },
};
</script>

<style lang="scss"></style>
