<template>
  <v-dialog v-model="showDialogBox" width="800" scrollable persistent>
    <v-card class="received-form">
      <v-card-title class="headline primary white--text" primary-title>Box info</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{{ selectedItem.id }}</td>
              </tr>
              <tr>
                <td>Batch number</td>
                <td>{{ selectedItem.boxNumber }}</td>
              </tr>
              <tr>
                <td>Brand</td>
                <td>{{ selectedItem.brand }}</td>
              </tr>
              <tr>
                <td>Amount of vials</td>
                <td>{{ selectedItem.amountOfVials }}</td>
              </tr>
              <tr>
                <td>Dosage of vials</td>
                <td>{{ selectedItem.dosagePerVial }}</td>
              </tr>
              <tr>
                <td>Current dosage level</td>
                <td>{{ selectedItem.currentDosageLevel }}</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>{{ selectedItem.location }}</td>
              </tr>
              <tr>
                <td>Date transfer</td>
                <td>{{ selectedItem.creation }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ selectedItem.state }}</td>
              </tr>
              <tr>
                <td>Note</td>
                <td>{{ selectedItem.note }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['showDialogBox', 'selectedItem'],
  methods: {
    closeModal() {
      this.$parent.showDialogBox = false;
    },
  },
};
</script>

<style lang="scss"></style>
