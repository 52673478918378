<template>
  <v-dialog v-model="showDialogInventory" width="800" scrollable persistent>
    <v-card class="inventory-entry-form">
      <v-card-title class="headline primary white--text" primary-title>Insert new vaccine box</v-card-title>
      <v-card-text>
        <v-form style="max-width: 450px; margin: 0 auto" ref="inventoryForm">
          <!-- Brand -->
          <v-select
            :items="manufacturers"
            item-text="brandName"
            item-value="id"
            label="Brand"
            v-model="formData.brand"
            :rules="[(v) => !!v || 'This field is required']"
            class="required mt-10"
          ></v-select>

          <!-- Batch number -->
          <v-text-field
            label="Batch number"
            type="text"
            :rules="rulesBoxNumber"
            class="required"
            counter="50"
            v-model="formData.boxNumber"
          ></v-text-field>

          <!-- Amount of vials -->
          <v-text-field
            label="Amount of vials"
            type="number"
            v-model="formData.amountOfVials"
            :rules="[(v) => !!v || 'This field is required']"
            class="required"
          ></v-text-field>

          <!-- Dosage -->
          <v-text-field
            label="Dosage per vial"
            type="number"
            v-model="formData.dosagePerVial"
            :rules="[(v) => !!v || 'This field is required']"
            class="required"
          ></v-text-field>

          <!-- Location -->
          <v-select :items="locations" item-text="name" item-value="id" label="Location" v-model="formData.location" :disabled="disabled"></v-select>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()">Cancel</v-btn>
        <v-btn color="primary" :disabled="submittingForm" @click="submit()" class="mx-4">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { InventoryFormDataKeys } from '@/views/VaccineViews/VaccineInventoryPage/data/inventory-form-data.js';

export default {
  props: ['showDialogInventory', 'locations', 'manufacturers'],
  data() {
    return {
      submittingForm: false,
      formDataPrestine: JSON.stringify(new InventoryFormDataKeys()),
      formData: new InventoryFormDataKeys(),
      // brands: [
      //   { id: 1, name: 'Moderna' },
      //   { id: 2, name: 'Pfizer' },
      // ],
      boxLength: 50,
      rulesBoxNumber: [
        (v) => !!v || 'This field is required',
        // (v) => (v && v.length <= this.boxLength) || `Batch number is ${this.boxLength} characters long`,
        // (v) => (v && v.length >= this.boxLength) || `Batch number is ${this.boxLength} characters long`,
      ],
      disabled: true,
    };
  },
  created() {
    this.formData.location = 1;
  },
  mounted() {
    console.log(this.manufacturers);
  },
  methods: {
    submit() {
      if (!this.$refs.inventoryForm.validate()) return;

      const payload = {
        brand: { id: this.formData.brand },
        boxNumber: this.formData.boxNumber,
        amountOfVials: this.formData.amountOfVials,
        dosagePerVial: this.formData.dosagePerVial,
        location: { id: this.formData.location },
      };

      this.submittingForm = true;
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment...' });
      this.$store.dispatch('submitVaccineEntryForm', payload).then((data) => {
        this.submittingForm = false;
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        this.$parent.showDialogInventory = false;

        if (data) {
          this.resetForm();
          this.$parent.getVaccineEntriesFiltered();
          let payload = {
            open: true,
            text: 'Entry successfully created.',
            color: 'success',
          };
          this.$store.commit('SET_SNACKBAR', payload);
        }
      });
    },
    closeModal() {
      this.$parent.showDialogInventory = false;
      this.resetForm();
    },
    resetForm() {
      this.formData = JSON.parse(this.formDataPrestine);
      this.formData.location = 1;
    },
  },
};
</script>

<style lang="scss"></style>
