export class InventoryFormDataKeys {
  constructor() {
    return {
      brand: null,
      boxNumber: null,
      amountOfVials: null,
      dosagePerVial: null,
      location: null
    }
  }
}