<template>
  <v-dialog v-model="showDialogReceived" width="800" scrollable persistent>
    <v-card class="received-form">
      <v-card-title class="headline primary white--text" primary-title>Received batch</v-card-title>
      <v-card-text>
        <v-alert icon="mdi-alert-circle" prominent text dense color="info" class="mt-5 mb-5 pa-4" v-if="selectedItem">
          <div class="ms-2">
            <h3 class="mb-1 pb-0">Please confirm receiving:</h3>
            <strong>{{ selectedItem.amountOfVials }}</strong> vial(s) of <strong>{{ selectedItem.brand }}</strong> from box
            <strong class="mt-2">{{ selectedItem.boxNumber }}</strong>
          </div>
        </v-alert>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()">Cancel</v-btn>
        <v-btn color="primary" @click="confirmRecieved()" class="ma-2">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['showDialogReceived', 'selectedItem'],
  methods: {
    confirmRecieved() {
      const payload = {
        amountOfVials: this.selectedItem.amountOfVials,
        id: this.selectedItem.id,
      };
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment...' });
      this.$store.dispatch('confirmReceivedVaccine', payload).then((data) => {
        this.submittingForm = false;
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        this.$parent.getVaccineEntriesFiltered();
        //this.showDialogReceived = false;
        this.$parent.showDialogReceived = false;

        if (data) {
          let payload = {
            open: true,
            text: 'Confirmed received vaccines',
            color: 'success',
          };
          this.$store.commit('SET_SNACKBAR', payload);
        }
      });
    },

    closeModal() {
      //this.showDialogReceived = false;
      this.$parent.showDialogReceived = false;
    },
  },
};
</script>

<style lang="scss"></style>
