export class TransferFormDataKeys {
  constructor() {
    return {
      fullBox: null,
      amountOfVials: null,
      location: null,
      note: null,
      totalTransferDosage: null
    };
  }
}
