<template>
  <div class="inventory-tbl">
    <v-btn fab bottom right color="primary" fixed large @click="openInventoryModal()" :disabled="!locationLoaded">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <template>
      <v-card>
        <div class="d-flex">
          <v-card-title class="align-start">
            <div>Vaccine inventory</div>
          </v-card-title>
          <v-spacer></v-spacer>
          <div style="flex: 2" class="me-8 mb-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              v-on:keyup.enter="updateVaccineEntries($event)"
              :disabled="loadingData"
            ></v-text-field>
            <div class="d-flex align-center justify-end mt-4">
              <v-checkbox
                style="display: none"
                v-model="hideZeroDosageBachtes"
                label="Hide empty batches"
                @change="getVaccineEntriesFiltered()"
              ></v-checkbox>
              <div class="ms-4">
                <v-btn small elevation="0" @click="changeSortDirection()">
                  <v-icon>{{ sort == 'DESC' ? 'mdi-sort-descending' : 'mdi-sort-ascending' }}</v-icon>
                </v-btn>
                <span class="caption ms-1">Sort: {{ sort == 'DESC' ? 'DESC' : 'ASC' }}</span>
              </div>
            </div>
          </div>
        </div>

        <v-data-table
          disable-pagination
          :headers="headers"
          :items="vaccineEntries"
          :loading="loadingData"
          disa
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item.dosisExpiration="{ item }">
            <v-chip small :color="expiredChipColor(item.dosisExpiration)" outlined>{{ item.dosisExpiration.date }}</v-chip>
          </template>
          <template v-slot:item.boxNumber="{ item }">
            <div @click="showBoxModal(item)" class="primary--text box-number">{{ item.boxNumber }}</div>
          </template>
          <template v-slot:item.stateDisplay="{ item }">
            <v-chip class="ma-2 font-weight-medium" color="success" outlined small v-if="item.state == 'DELIVERED'">Accepted</v-chip>
            <v-chip class="ma-2 font-weight-medium" color="warning" outlined small v-if="item.state == 'IN_TRANSFER'">Inbound</v-chip>
          </template>
          <template v-slot:item.state="{ item }">
            <v-tooltip right v-if="item.state == 'DELIVERED'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  class="ma-2"
                  @click="showTransferModal(item)"
                  :disabled="item.currentDosageLevel <= 0"
                >
                  <v-icon dark>mdi-swap-horizontal</v-icon>
                </v-btn>
              </template>
              <span>Transfer</span>
            </v-tooltip>

            <v-tooltip right v-if="item.state == 'IN_TRANSFER'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small fab elevation="1" color="success" class="ma-2 white--text" @click="showReceivedModal(item)">
                  <v-icon dark>mdi-truck-check</v-icon>
                </v-btn>
              </template>
              <span>Mark received</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-btn class="ma-2" outlined icon @click="prev()" :disabled="disabledPrev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn class="ma-2" outlined icon @click="next()" :disabled="disabledNext">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-card>
    </template>

    <!-- Modals -->
    <InventoryFormModal :showDialogInventory="showDialogInventory" :locations="warehouseLocations" :manufacturers="brands" />
    <TransferModal
      v-if="showDialogTransfer"
      :showDialogTransfer="showDialogTransfer"
      :locations="locations"
      :selectedItem="selectedItem"
      :search="search"
    />
    <ReceivedModal :showDialogReceived="showDialogReceived" :selectedItem="selectedItem" />
    <BoxModal :showDialogBox="showDialogBox" :selectedItem="selectedItem" />
  </div>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import TransferModal from '@/views/VaccineViews/VaccineInventoryPage/components/transfer-modal.vue';
import ReceivedModal from '@/views/VaccineViews/VaccineInventoryPage/components/received-modal.vue';
import BoxModal from '@/views/VaccineViews/VaccineInventoryPage/components/box-modal.vue';
import InventoryFormModal from '@/views/VaccineViews/VaccineInventoryPage/components/inventory-modal.vue';

import moment from 'moment';

export default {
  mixins: [SharedFunctions],
  components: { TransferModal, ReceivedModal, BoxModal, InventoryFormModal },
  data() {
    return {
      hasAccessToPage: false,
      showDialog: false,
      entries: [],
      showDialogTransfer: false,
      showDialogReceived: false,
      showDialogBox: false,
      showDialogInventory: false,
      headers: [
        {
          text: 'Brand',
          align: 'start',
          sortable: false,
          value: 'brand',
        },
        { text: 'Batch number', value: 'boxNumber', sortable: false },
        { text: 'Initial amount of vials', value: 'amountOfVials', sortable: false },
        { text: 'Dosage per vial', value: 'dosagePerVial', sortable: false },
        { text: 'Total dosages', value: 'currentDosageLevel', sortable: false },
        { text: 'Location', value: 'location', sortable: false },
        { text: 'Date transfer', value: 'creation', sortable: false },
        { text: 'Expires on', value: 'dosisExpiration', sortable: false },
        { text: 'State', value: 'stateDisplay', sortable: false },
        { text: 'Actions', value: 'state', sortable: false },
      ],
      search: '',
      index: 0,
      itemsPerPage: 25,
      totalCount: this.$store.state.vaccinePage.vaccineEntriesSize,
      page: 1,
      pageCount: 0,
      disabledPrev: true,
      disabledNext: true,
      loadingData: false,
      locationLoaded: false,
      brands: [],
      hideZeroDosageBachtes: false,
      sort: 'DESC',
    };
  },
  watch: {
    search(value) {
      if (value == '') this.getVaccineEntriesFiltered();
    },
    index(value) {
      if (value < 0) {
        this.index = 0;
        this.disabledPrev = true;
      } else if (value == 0) {
        this.disabledPrev = true;
      } else {
        this.disabledPrev = false;
      }
      this.getVaccineEntriesFiltered();
    },
  },
  computed: {
    vaccineEntries: {
      get() {
        return (
          this.$store.state.vaccinePage.vaccineEntries
            // .filter((entry) => {
            //   if (!this.hideZeroDosageBachtes) return entry;
            //   else if (entry.currentDosageLevel > 0) return entry;
            // })
            .map((entry) => {
              return {
                amountOfVials: entry.amountOfVials,
                boxNumber: entry.boxNumber,
                brand: entry.brand ? entry.brand.brandName : '',
                dosagePerVial: entry.dosagePerVial,
                currentDosageLevel: entry.currentDosageLevel + ' / ' + entry.totalStartDosage,
                location: entry.location ? entry.location.name : '',
                creation: entry.creation ? this.formatDate(entry.creation) : '',
                state: entry.state,
                stateDisplay: entry.state,
                id: entry.id,
                note: entry.note ? entry.note : null,
                dosisExpiration: {
                  date: entry.dosisExpiration ? this.formatDate(entry.dosisExpiration) : 'NA',
                  expired: this.isExpired(entry.dosisExpiration),
                },
              };
            })
        );
      },
      set(value) {
        this.$store.commit('SET_VACCINE_ENTRIES', value);
      },
    },
    locations() {
      return this.$store.state.vaccine.locations;
    },
    manufacturers() {
      return this.$store.state.vaccinePage.manufacturers;
    },
  },
  mounted() {
    this.getVaccineEntriesFiltered();
    if (this.manufacturers.length == 0) {
      this.getVaccineManufacturers();
    } else {
      this.brands = this.manufacturers;
    }

    if (this.locations.length == 0) {
      this.getLocations();
    } else {
      this.warehouseLocations = this.locations;
      this.locationLoaded = true;
    }
  },
  methods: {
    showTransferModal(item) {
      this.showDialogTransfer = true;
      this.selectedItem = item;
    },
    showReceivedModal(item) {
      this.showDialogReceived = true;
      this.selectedItem = item;
    },
    showBoxModal(item) {
      this.showDialogBox = true;
      this.selectedItem = item;
    },
    getVaccineEntriesFiltered() {
      const payload = new URLSearchParams({
        quickFilter: this.search,
        index: this.index * this.itemsPerPage,
        pageSize: this.itemsPerPage,
        hideZero: this.hideZeroDosageBachtes,
        sort: this.sort,
      });

      this.loadingData = true;
      this.$store
        .dispatch('getVaccineEntries', payload.toString())
        .then((data) => {
          this.$store.commit('SET_VACCINE_ENTRIES', data);
          this.vaccineEntries = data;
          window.scrollTo(0, 0);
          if (data.length == 0) {
            this.disabledNext = true;
          } else {
            this.disabledNext = false;
          }
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    updateVaccineEntries(value) {
      if (value != '') this.getVaccineEntriesFiltered();
    },
    next() {
      this.index++;
    },
    prev() {
      this.index--;
    },
    openInventoryModal() {
      this.showDialogInventory = true;
    },
    getLocations() {
      this.$store.dispatch('getVaccineLocations').then((data) => {
        this.locationLoaded = true;
        if (data) {
          const warehouseLocations = data.filter((v) => v.dossageAllow === false);
          this.warehouseLocations = warehouseLocations;
        }
      });
    },
    getVaccineManufacturers() {
      this.$store.dispatch('getVaccineManufacturers').then((data) => {
        this.brands = data;
      });
    },
    isExpired(date) {
      if (moment(date, 'YYYY-MM-DD').isAfter(moment())) return false;
      else return true;
    },
    expiredChipColor(dosisExpiration) {
      if (dosisExpiration.date == 'NA') return '';
      else {
        if (dosisExpiration.expired) return 'error';
        else return 'success';
      }
    },
    changeSortDirection() {
      if (this.sort == 'DESC') this.sort = 'ASC';
      else if (this.sort == 'ASC') this.sort = 'DESC';
      this.getVaccineEntriesFiltered();
    },
  },
};
</script>

<style lang="scss">
.inventory-tbl {
  .box-number {
    cursor: pointer;
    max-width: 200px;
  }
}
</style>
